import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import DNRNPolicy from './../../components/dnrn-policy'
import TermsMenu from './../../components/terms-menu'

const IndexPage = () => (
  <Layout>
    <SEO
      title='Domain Renewal Notification Policy'
      keywords={[`web hosting`, `domain names`, `web site`, `search engine optimization`, `hosting`, `servers`]}
      pageType={'fos'}
      flowType={'none'}
    />
    <TermsMenu />
    <DNRNPolicy />
  </Layout>
)

export default IndexPage
