import React from 'react'

import './style/dnrn-policy.scss'

const DNRNPolicy = () => (
  <div className="row-wrapper copy-right dnrn-policy">
    <div className="container">
      <section className="policy-agree">
        <div className="row">
          <div className="col-sm-12">
            <h2>WebsiteBuilder Domain Name Renewal Notification Policy</h2>
          </div>
        </div>
      </section>
    </div>

    <div className="container tos">
      <h1 className="h2">WebsiteBuilder Domain Name Renewal Notification Policy</h1>

      <div className="row">
        <div className="col-xs-12">
          <ul className="legal">
            <p>For domain registration services, email reminders are sent approximately one month prior to the domain expiration date and again approximately one week
              prior to the domain expiration date. If the domain is not renewed by the expiration date, an additional email notification will be sent within five days of
              the domain expiration date. All email reminders are sent to the registrant email address listed for the domain name.</p>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

export default DNRNPolicy
